import gsap from '../js/gsap';
import {
    ScrollTrigger
} from '../js/gsap/ScrollTrigger';
import {
    ScrollSmoother
} from '../js/gsap/ScrollSmoother';
gsap.registerPlugin(ScrollTrigger, ScrollSmoother);



          var tl = gsap.timeline();
          tl.to(".line h2", {
            y: 0,
            stagger: 0.2
        
        })
        tl.to(".page__list__item", {
            opacity: 1,
            stagger: 0.4
        
        });


        gsap.to(".hero__image", {
            scrollTrigger:{
            trigger: ".hero__image",
            start: "top center",
            scrub: true,
          
        
        
    },
            scale: 1.17,
            duration: 0.4

        })
        gsap.to("form", {
            scrollTrigger:{
            trigger: "form",
            start: "top 80%",
            // markers: true,
    },
            opacity: 1,
            delay: 0.3,
            duration: 0.6
          
           
            

        });




       let smoother = ScrollSmoother.create({
           wrapper: '#smooth-wrapper',
           wrapper: '#smooth-content',
           smoooth: 2,
           smoothTouch: 0.1
       })
        
        

       let button = document.querySelector("#jump");

       button.addEventListener("click", (e) => {
           gsap.to(smoother, {
           scrollTop: smoother.offset(".registration__form", "center center"),
           duration: 2,
           ease: 'back.out'
       })
           
       });


      ScrollTrigger.matchMedia({

        "(max-width: 767px)": function() {


            button.addEventListener("click", (e) => {
                gsap.to(smoother, {
                scrollTop: smoother.offset(".registration__form", "top top"),
                duration: 2,
                ease: 'back.out'
            })
                
            });



        }
    });
            
      